<template>
  <div class="content">
    <section class="section_presentation">
      <h1 id="title_gbk">gabriel kummer</h1>
      <span id="title_def">Etudiant en Informatique à Porrentruy</span>
    </section>
    <!--
    <div class="pp_container">
      <img src="../assets/pp_gbkmr.jpg" class="img_pp_gbkmr">
    </div>
    -->
    <div class="presentation_txt">
      <h2>présentation</h2>
      <span class="separateur_petit"></span>
      <div class="text_presentation_container">
        <div class="margin_right_component_text">
          <p>
            Je m'appelle <strong>Gabriel Kummer</strong>, j'ai 18 ans et suis passionné d'informatique.
            J'ai commencé ma formation d'informaticien en août 2020 et je suis maintenant dans ma 3e année à <strong>l'école des métiers techniques de Porrentruy</strong>.
            Je pense par la suite m'orienter vers le développement web.
          </p>
        </div>
        <div>
          <p>
            Mes hobbys sont principalement la <strong>musique, le rap, les jeux vidéos et les sorties.</strong>
            Tout mon cursus scolaire s'est passé à Bassecourt, dont le village ou j'habite actuellement.
          </p>
        </div>
      </div>
    </div>
    <section class="section_parcour">
      <div class="presentation_txt">
        <h2>parcours scolaire</h2>
        <span class="separateur_petit"></span>
        <div class="container_parcour_content">
          <ul>
            <li>Ecole primaire de la Haute-Sorne de Bassecourt - 5 années</li>
            <li>Ecole secondaire de la Haute-Sorne de Bassecourt - 3 années</li>
            <li>Ecole des métiers techniques de Porrentruy - 4 années (année courante : 3)</li>
          </ul>
        </div>
      </div>
    </section>
    <section class="section_card">
      <div class="presentation_txt">
        <h2>compétences</h2>
        <span class="separateur_petit"></span>
      </div>
      <section class="card_section">
        <div class="card_container card_container1">
          <a>
            <div class="container_title_card">
              <span class="cardTitle">Languages</span>
            </div>
            <div class="card_content">
              <div class="myComp">
                <span class="compDef">C++</span>
                <div class="compDisplay">
                  <div class="compPer yellow-outline" style="max-width: 70%"></div>
                </div>
              </div>
              <div class="myComp">
                <span class="compDef">HTML</span>
                <div class="compDisplay">
                  <div class="compPer yellow-outline" style="max-width: 100%"></div>
                </div>
              </div>
              <div class="myComp">
                <span class="compDef">JAVA</span>
                <div class="compDisplay">
                  <div class="compPer yellow-outline" style="max-width: 60%"></div>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div class="card_container card_container2">
          <a>
            <div class="container_title_card">
              <span class="cardTitle">Langues</span>
            </div>
            <div class="card_content">
              <div class="myComp">
                <span class="compDef">Français</span>
                <div class="compDisplay">
                  <div class="compPer orange-outline" style="max-width: 100%"></div>
                </div>
              </div>
              <div class="myComp">
                <span class="compDef">Anglais</span>
                <div class="compDisplay">
                  <div class="compPer orange-outline" style="max-width: 90%"></div>
                </div>
              </div>
              <div class="myComp">
                <span class="compDef">Allemand</span>
                <div class="compDisplay">
                  <div class="compPer orange-outline" style="max-width: 40%"></div>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div class="card_container card_container3">
          <a>
            <div class="container_title_card">
              <span class="cardTitle">Autres</span>
            </div>
            <div class="card_content">
              <div class="myComp">
                <span class="compDef">Office</span>
                <div class="compDisplay">
                  <div class="compPer blue-outline" style="max-width: 100%"></div>
                </div>
              </div>
              <div class="myComp">
                <span class="compDef">Adobe</span>
                <div class="compDisplay">
                  <div class="compPer blue-outline" style="max-width: 60%"></div>
                </div>
              </div>
              <div class="myComp">
                <span class="compDef">Inkscape</span>
                <div class="compDisplay">
                  <div class="compPer blue-outline" style="max-width: 100%"></div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </section>
      <section class="section_creations">
        <div class="presentation_txt">
          <h2>créations</h2>
          <span class="separateur_petit"></span>
        </div>
        <div class="creation_container">
          <div class="creation_hover ch1">
            <div><span class="material-symbols-outlined an1">chevron_right</span><a href="https://kumgab.divtec.me/carwallpaper/" target="_blank"><span>Car Wallpaper - 2022</span></a><span class="site_name_maker">Gabriel K.</span></div>
          </div>
          <div class="creation_hover ch2">
            <div><span class="material-symbols-outlined an2">chevron_right</span><a href="https://kumgab.divtec.me/PGCB/" target="_blank"><span>Golf Club Bassecourt - 2020</span></a><span class="site_name_maker">Gabriel K.</span></div>
          </div>
          <div class="creation_hover ch3">
            <div><span class="material-symbols-outlined an3">chevron_right</span><a href="https://kumgab.divtec.me/voyage/" target="_blank"><span>Voyage - 2020</span></a><span class="site_name_maker">Gabriel K.</span></div>
          </div>
        </div>
      </section>
    </section>
    <div class="footer_container">
      <div>

        Gabriel Kummer<br>
        Etudiant Informatique - Divtec<br>

        Porrentruy 2900<br><br>

        gabriel.kummer@divtec.ch<br>
        Bassecourt 2854<br>
        &copy; Site by @kummgab<br>

      </div>
    </div>
  </div>

</template>

<script>
import {
  gsap
} from 'gsap';

export default {

  mounted: function() {
    this.startAnimations()
  },

  methods: {
    startAnimations: function () {
      let tl = gsap.timeline({onUpdate:updatePercentage});

      tl.from('#title_gbk', {duration: 2, opacity: 0}, 0)
      tl.from('#title_def', {duration: 2, opacity: 0}, 0.2)

      gsap.to('.card_container1', {
        scrollTrigger: {
          trigger: '.card_section'
        },
        duration: 0.4,
        opacity: 1,
      }, 0.5);

      gsap.to('.card_container2', {
        scrollTrigger: {
          trigger: '.card_section'
        },
        duration: 0.4,
        opacity: 1
      }, 0.75);

      gsap.to('.card_container3', {
        scrollTrigger: {
          trigger: '.card_section'
        },
        duration: 0.4,
        opacity: 1
      }, 1);

      const scene = this.$scrollmagic.scene({
         triggerElement: '.section_presentation',
         triggerHook: 0,
         duration: '300%'
      })
          .setPin('.sectuib:presentation')
          .setTween(tl)

      this.$scrollmagic.addScene(scene)

      function updatePercentage() {
        tl.progress()
      }

    }
  },

  name: "ContentView",
  components: {},

}

</script>

<style scoped>

* {
  color: var(--white);
}

#title_gbk {
  text-align: center;
}

.content {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  background: var(--black);
}

.section_presentation {
  width: 100%;
  height: 500px;
  background: rgba(0,0,0,0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: rgba(0,0,0,0.3) 0px 10px 50px -12px inset, rgba(0, 0, 0, 0.1) 0 18px 56px 8px inset;
}

.section_presentation h1 {
  color: var(--white);
  font-size: 3em;
}

.section_presentation span {
  color: var(--white);
  font-size: 1.3em;
}

.section_parcour {
  background: rgba(0,0,0,0.3);
  box-shadow: rgba(0,0,0,0.3) 0px 10px 50px -12px inset, rgba(0, 0, 0, 0.1) 0 18px 56px 8px inset;
}

.presentation_txt .separateur_petit {
  width: 80px;
  height: 5px;
  position: absolute;
  display: block;
  background: rgba(0,0,0,0.3);
}


.text_presentation_container {
  display: flex;
  justify-content: space-evenly;
}

.presentation_txt {
  padding: 4em 6em 4em 6em;
  width: 90%;
  margin: auto;
}

.presentation_txt div {
  display: flex;
  justify-content: space-between;
}

.presentation_txt div div {
  width: 600px;
}

.presentation_txt div p {
  line-height: 30px;
  font-size: 1.1em;
}

.margin_right_component_text {
  margin-right: 2em;
}

hr {
  background: rgba(0,0,0,0.3);
}

.container_parcour_content {
  display: flex;
  align-items: center;
}

.container_parcour_content ul li {
  line-height: 50px;
  list-style-type: var(--black);
  font-style: italic;
  font-size: 1.1em;
}

.card_section {
  height: 400px;
  margin: auto;
  display: flex;
  gap: 20px;
  justify-content: space-evenly;
  width: 80%;
}

.card_container {
  color: var(--white);
  background: rgba(0,0,0,0.3);
  width: 350px;
  height: 250px;
  border-radius: 20px;
  box-shadow: rgba(0,0,0,0.3) 0 8px 24px;
  transition: 0.4s all;
  padding: 2em;
  margin: 1em auto 1em auto;
}

.card_container:hover {
  transform: translateY(-10px);
  cursor: pointer;
  box-shadow: rgba(0,0,0,0.3) 0 7px 29px 0;
}

.container_title_card {
  margin-bottom: 1.5em;
}

.cardTitle {
  color: var(--white);
  font-weight: bold;
  font-size: 2.5em;
}

.card_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.myComp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 30px;
}

.compDef {
  font-style: italic;
  margin-right: 1em;
}

.compDisplay {
  width: 160px;
  height: 10px;
  border-radius: 5px;
  position: relative;
  background: rgba(0,0,0, 0.1);
}

.compPer {
  height: 10px;
  background: var(--white);
  border-radius: 5px;
}

.section_creations {
  background: rgba(0,0,0,0.3);
  box-shadow: rgba(0,0,0,0.3) 0px 10px 50px -12px inset, rgba(0, 0, 0, 0.1) 0 18px 56px 8px inset;
}

.section_creations .creation_container {
  display: flex;
  margin: auto;
  align-items: center;
  flex-direction: column;
  max-width: 600px;
  padding-bottom: 4em;
}

.creation_hover:hover {
  background: rgba(0,0,0,0.1);
}

.creation_container .material-symbols-outlined {
  transition: all ease-in-out 0.2s;
  opacity: 0;
  transform: translateX(-50px);
}

.ch1:hover .an1 {
  transform: translateX(20px) scale(1.3) rotate(90deg);
  opacity: 1;
}

.ch2:hover .an2 {
  transform: translateX(20px) scale(1.3) rotate(90deg);
  opacity: 1;
}

.ch3:hover .an3 {
  transform: translateX(20px) scale(1.3) rotate(90deg);
  opacity: 1;
}

.creation_container div {
  border-radius: 5px;
  width: 100%;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
}

.creation_container div a {
  color: black;
}

.creation_container div a:hover {
  text-decoration: underline var(--white);
}

.footer_container {
  padding: 2em;
  width: 100%;
  background: var(--black);
  color: var(--white);
  text-align: center;
  position: center;
  line-height: 30px;
  z-index: 100;
  bottom:0;
  left: 0;
  font-size: 0.8rem;
}

@media only screen and (max-width: 1000px) {
  /* For tablets: */
  .card_section {
    flex-direction: column;
    text-align: center;
    height: 100%;
    align-items: center;
    padding-top: 6em;
  }

  .text_presentation_container {
    flex-direction: column;
    align-items: center;
  }

  .presentation_txt div div {
    width: auto;
  }

  .presentation_txt {
    padding: 0.5em;
  }

  .ch1 .an1 {
    transform: translateX(20px) scale(1.3) rotate(90deg);
    opacity: 1;
  }

  .ch2 .an2 {
    transform: translateX(20px) scale(1.3) rotate(90deg);
    opacity: 1;
  }

  .ch3 .an3 {
    transform: translateX(20px) scale(1.3) rotate(90deg);
    opacity: 1;
  }

  .container_parcour_content ul {
    margin-left: -40px;
    list-style-type: none;
  }

  .container_parcour_content ul li {
    margin-bottom: 2em;
  }

  .card_section {
    padding-top: 3em;
    padding-bottom: 3em;
  }

  .section_creations .creation_container {
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 675px) {
  /* For smartphone: */

  .site_name_maker {
    display: none;
  }
}

</style>
