<template>
  <div class="hide_div_animate">
    <div class="animation_plane_1"></div>
    <div class="animation_plane_2"></div>
  </div>
  <headerComponent @changeMainColor="changeMainColor"></headerComponent>
  <router-view/>
</template>


<style lang="scss">

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  font-family: 'Sora', sans-serif;
}
body {
  background: var(--black);
}

a {
  text-decoration: none;
}

h1, h2, h3 {
  font-weight: bold;
  font-family: 'Major Mono Display', monospace;
}

hr {
  width: 70%;
  background: lightgray;
  height: 1px;
  border: none;
}

.hide_div_animate {
  overflow: hidden;
  top: 0;
  left: 0;
  display: block;
  position: absolute;
  height: 500px;
  width: 100%;
}

.animation_plane_1 {
  height: 1000px;
  width: 50px;
  background: rgba(0,0,0,0.1);
  position: absolute;
  transform: rotate(30deg) translateY(-300px) translateX(300px);
  display: block;
  animation: header_animation1 2s;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;}

.animation_plane_2 {
  height: 1000px;
  width: 100px;
  background: rgba(0,0,0,0.1);
  position: absolute;
  transform: rotate(30deg) translateY(-300px) translateX(100px);
  animation: header_animation2 2.5s;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;}

:root {
  --black: #2F3E46;
  --white: white;

  --darkred: #780000;
  --darkblue: #4a4e69;
  --darkbraun: #7f5539;
  --darkpurple: #6a4c93;
  --darkgreen: #899878;
}

@keyframes header_animation1 {
  from {
    transform: rotate(30deg) translateY(-200px) translateX(-200px);
    opacity: 0;
  }
  to {
    transform: rotate(30deg) translateY(-300px) translateX(300px);
    opacity: 1;
  }
}

@keyframes header_animation2 {
  from {
    transform: rotate(30deg) translateY(-100px) translateX(-200px);
    opacity: 0;
  }
  to {
    transform: rotate(30deg) translateY(-300px) translateX(100px);
    opacity: 1;
  }
}

</style>
<script>
import HeaderComponent from "@/components/headerComponent";
import ContentView from "@/views/ContentView";
import FooterView from "@/components/FooterView";
import { gsap } from 'gsap';
/*
import ScrollAnimation from './directives/scrollanimation';

Vue.directive('scrollanimation', ScrollAnimation);

new Vue({
  render:  h => h(App),
}).$mount('#app')

 */
export default {
  components: {
    FooterView, ContentView, HeaderComponent
  },

  methods: {
    changeMainColor(color) {
      let root = document.documentElement
      root.style.setProperty('--black', color)
    }
  }


}
</script>
