<template>
  <div class="footer_container">
    <div>

      Gabriel Kummer<br>
      Etudiant Informatique - Divtec<br>

      Porrentruy 2900<br><br>

      gabriel.kummer@divtc.ch<br>
      Bassecourt 2854<br>
      Site by @kummgab<br>

    </div>
  </div>
</template>

<script>
export default {
  name: "FooterView"
}
</script>

<style scoped>

.footer_container {
  padding: 2em;
  width: 100%;
  background: var(--black);
  color: var(--white);
  text-align: center;
  line-height: 30px;
  z-index: 100;
  bottom:0;
  left: 0;
}
</style>
