<template>
  <div class="header">
    <div class="container_color_change">
      <div class="change_color_choices">
        <div @click="this.$emit('changeMainColor', '#2F3E46')"></div>
        <div @click="this.$emit('changeMainColor', '#640d14')"></div>
        <div @click="this.$emit('changeMainColor', '#4a4e69')"></div>
        <!--
        <div @click="this.$emit('changeMainColor', '#7f5539')"></div>
        <div @click="this.$emit('changeMainColor', '#6a4c93')"></div>
        <div @click="this.$emit('changeMainColor', '#899878')"></div>
        -->
      </div>
    </div>
    <div>
      <nav class="navMenu">
        <router-link to="/"><span class="header_link">Accueil</span></router-link>
        <router-link to="/contact"><span class="header_link">Me contacter</span></router-link>
      </nav>
      <a href="https://kumgab.divtec.me/carwallpaper/" target="_blank"><button class="btn_dernier_site">Mon Dernier Site</button></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "headerComponent",

  mounted() {

  }
}
</script>

<style scoped>

hr {
  width: 95%;
}

.header {
  width: 100%;
  display: flex;
  position: absolute;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
  padding: 1em;
}

.header div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header div span {
  color: var(--white);
  font-size: 1.2rem;
  margin-right: 2em;
}

.header div span:hover {
  color: #CAD2C5;
  cursor: pointer;
}

.header div .material-symbols-outlined {
  margin: 0;
  font-size: 1.2em;
}

.container_color_change {
  display: flex;
  justify-content: left;
  align-items: center;
  z-index: 100;
}

.container_color_change .change_color_div {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 140%;
}

.container_color_change .change_color_choices {
  height: 30px;
  display: flex;
  gap: 10px;
  margin-left: 1em;
}

.container_color_change .change_color_choices :nth-child(1) {
  background: #2F3E46;
  animation: anim_color_1 0.5s;
}

.container_color_change .change_color_choices :nth-child(2) {
  background: var(--darkred);
  animation: anim_color_1 0.5s;
}

.container_color_change .change_color_choices :nth-child(3) {
  background: var(--darkblue);
  animation: anim_color_1 0.5s;
}

.container_color_change .change_color_choices :nth-child(4) {
  background: var(--darkbraun);
  animation: anim_color_1 0.5s;
}

.container_color_change .change_color_choices :nth-child(5) {
  background: var(--darkpurple);
  animation: anim_color_1 0.5s;
}

.container_color_change .change_color_choices :nth-child(6) {
  background: var(--darkgreen);
  animation: anim_color_1 0.5s;
}



.container_color_change .change_color_choices div {
  width: 30px; height: 30px;
  transition: 0.2s ease-in-out all;
  border-radius: 100px;
  opacity: 1;
}

.container_color_change .change_color_div div:hover {
  cursor: pointer;
}

.container_color_change .change_color_choices div:hover {
  cursor: pointer;
}

button {
  padding: 1em;
  border: solid 3px var(--white);
  background: transparent;
  color: var(--white);
  font-size: 1.2rem;
  outline: none;
}

button:hover {
  color: var(--black);
  background: var(--white);
  cursor: pointer;
}

@keyframes anim_color_1 {
  from {
    transform: translateX(-20px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@media only screen and (max-width: 675px) {
  /* For smartphone: */
  .header {
    padding: 2em;
    justify-content: space-between;
  }

  .btn_dernier_site {
    display: none;
  }

  .container_color_change {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 2em;
    height: 200px;
  }

  .container_color_change .change_color_choices {
    flex-direction: column;
    height: auto;
    margin-right: 2em;
  }

  .container_color_change {
    flex-direction: column;
  }


}

</style>
