<template>
  <div class="content">
    <section class="section_presentation">
      <h1>me contacter</h1>
      <span>Vous pouvez me contactez via les liens ci-dessous</span>
    </section>
    <div class="form_container">
      <form action="mailto:gabriel.kummer@divtec.ch" method="get" enctype="text/plain">
        <input type="text" placeholder="Nom" name="name" v-model="name">
        <input type="text" placeholder="Prénom">
        <input type="text" placeholder="Adresse">
        <input type="email" placeholder="E-mail" name="email" v-model="email">
        <textarea placeholder="Message" rows="5"></textarea>
        <div>
          <button type="reset" value="reset">Effacer</button>
          <button type="submit" value="send">Envoyer</button>
        </div>
      </form>
    </div>
    <div class="reseau_container">
      <a href="mailto:gabriel.kummer.pro@gmail.com">
        <div>
          <img src="../assets/Outlook.png">
          <span>gabriel.kummer.pro@gmail.com</span>
          <div class="deco_div"></div>
        </div>
      </a>
      <a href="https://github.com/ElKummero" target="_blank">
        <div>
          <img src="../assets/Github.png">
          <span>ElKummero</span>
          <div class="deco_div"></div>
        </div>
      </a>
      <a href="https://twitter.com/GabrielKummer04" target="-_blank">
        <div>
          <img src="../assets/Twitter.png">
          <span>GabrielKummer04</span>
          <div class="deco_div"></div>
        </div>
      </a>
    </div>
    <div class="footer_container">
      <div>

        Gabriel Kummer<br>
        Etudiant Informatique - Divtec<br>

        Porrentruy 2900<br><br>

        gabriel.kummer.pro@gmail.com<br>
        Bassecourt 2854<br>
        &copy; Site by @kummgab<br>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ContactView",
  data() {
    return {
      name: '',
      email: '',
      message: ''
    }
  }
}
</script>

<style scoped>

.content {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  background: var(--black);
}

.section_presentation {
  width: 100%;
  height: 500px;
  background: rgba(0,0,0,0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: rgba(0,0,0,0.3) 0px 10px 50px -12px inset, rgba(0, 0, 0, 0.1) 0 18px 56px 8px inset;
}

.section_presentation h1 {
  color: var(--white);
  font-size: 3em;
}

.section_presentation span {
  color: var(--white);
  font-size: 1.3em;
}

.form_container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  max-width: 100vh;
  margin: auto;
  padding: 2em;
}

.form_container form div {
  align-items: flex-end;
}

.form_container form input, textarea {
  width: 100%;
  background: rgba(0,0,0,0.3);
  border: none;
  padding: 1.5em;
  color: white;
  resize: none;
  margin: 1em;
}

.form_container form button {
  width: auto;
  background: rgba(0,0,0,0.3);
  border: none;
  padding: 1.5em 2em 1.5em 2em;
  color: white;
  resize: none;
  margin: 1em;
}

.form_container form button:hover {
  cursor: pointer;
  background: rgba(0,0,0,0.2);
}

.form_container form button:active {
  background: rgba(0,0,0,0.5);
}

.form_container form textarea:focus {
  outline: none;
  background: rgba(0,0,0,0.5);
}

.form_container form input:focus {
  outline: none;
  background: rgba(0,0,0,0.5);
}

.footer_container {
  padding: 2em;
  width: 100%;
  background: var(--black);
  color: var(--white);
  text-align: center;
  position: center;
  line-height: 30px;
  z-index: 100;
  bottom:0;
  left: 0;
  font-size: 0.8rem;
}

.reseau_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
  overflow: hidden;
  position: relative;
  margin-top: 4em;
  margin-bottom: 4em;
}

.reseau_container div:not(.deco_div) {
  color: white;
  padding: 2em;
  background: var(--black);
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(0,0,0,0.3);
  transition: 0.2s all ease-in-out;
}

.reseau_container span {
  opacity: 0;
  transform: translateY(30px);
  transition: 0.2s all ease-in-out;
}

.reseau_container div:hover span {
  opacity: 1;
  transform: translateY(0);
}

.reseau_container div:hover img {
  transform: translateY(-10px);
}

.reseau_container div img {
  transition: 0.2s ease-in-out all;
  width: 100px;
  filter: invert();
}

.reseau_container div:not(.deco_div):hover {
  background: rgba(0,0,0,0.1);
  cursor: pointer;
}

.reseau_container div:hover .deco_div {
  position: absolute;
  background: rgba(0,0,0,0.2);
  width: 50px;
  height: 300px;
  transform: translateX(-20px) rotate(30deg);
}

.reseau_container .deco_div {
  transition: 0.4s all ease-in-out;
  position: absolute;
  background: rgba(0,0,0,0.2);
  width: 50px;
  height: 300px;
  transform: translateX(-40px) rotate(30deg);
}

@media only screen and (max-width: 1000px) {
  .reseau_container {
    flex-direction: column;
  }

  .reseau_container div .deco_div {
    display: none;
  }

  .reseau_container div span {
    opacity: 1;
    transform: translateY(0);
  }

  .reseau_container div img {
    transform: translateY(-10px);
  }
}

</style>
